.clickable {
    cursor: pointer;
    transition: transform 0.2s ease;
  }
  
  .clickable:hover {
    transform: scale(1.2);
  }
  .next {
    height: 2px;
    width: 2px;

  }    

  .chevron-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 20%;
    background-color: #f0f0f0;
   margin-left: 8px;
    font-size: 20px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }

  .chevron-button:hover {
    background-color: #e0e0e0;
  }

  .chevron-button.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }


  .next_prev {
    margin-left: 4px;
    

  }